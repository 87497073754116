import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import BuildPageCardUtil,{IBuildPageCardDataObj} from './buildPageCardUtil';
import SelectTag from './selectTag/SelectTag.vue';
import FieldCfg from './fieldCfg/FieldCfg.vue';
export default defineComponent ({
    name: 'buildPageCard',
    title: "构建页面",
    modelType:'card',
    fullscreen: true,
    components:{SelectTag,FieldCfg},
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IBuildPageCardDataObj=reactive<IBuildPageCardDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            formRef:null,
            form: { },
            rules:{
                tableName:[utils.UtilPub.commonValidRule('请选择主表')],
                modelPath:[utils.UtilPub.commonValidRule('请选择模块')],
                modelName:[utils.UtilPub.commonValidRule('请输入名称')],
                colNum:[utils.UtilPub.commonValidRule('请选择列数')],
            },
            compParams: {modelPath: utils.Api.buildUrl("/buildPage")},
            pageData:{
                config:{
                    tableName:'',//主表名称
                    modelName:'',//模块名称
                    modelPath:'',//应用于哪个模块
                    colNum:1,//卡片显示列数
                    fullscreen:true//卡片是否全屏
                },
                listCondition: {//列表查询条件
                    fieldArr:[],//已选字段集合
                    allFields:[]//下拉所有字段集合
                },
                listGrid: {//列表表格列
                    fieldArr:[],//已选字段集合
                    allFields:[]//下拉所有字段集合
                },
                cardForm:{//卡片表单
                    fieldArr:[],//已选字段集合
                    allFields:[]//下拉所有字段集合
                },
                cardDetails:{//卡片明细
                    tables:[]//所有的明细表格
                },
                allTables:[]//所有的数据库表
            },
            otherParams:{
                moveItem:{},//记录移动的某项（包含被移动项以及和交换位置的项；不管是表单、明细还是其它都共用这个字段）
                fieldItem: {},//正在双击修改的字段
                fieldCfgParam:{//传给字段属性设置组件的参数
                    type:0,//弹出字段属性设置的时候，根据类别控制显示哪些属性可以设置，哪些不可以设置
                    cfgName:''//当前正在设置哪种类别的字段属性（列表查询条件|列表表格列|卡片表单|卡片明细表格列）
                },
                cardDetails:{//卡片明细表格
                    visible:false,//增加明细表格弹出框控制字段
                    addTableName:'',//正在增加的明细表格名称
                    field:{//某个明细表格中的字段
                        fieldItem: {},//正在双击修改的明细字段
                        tableName: '',//记录双击的字段所属明细表格
                    }
                },
                allPathMenu:[],//所有的末级菜单下拉框
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BuildPageCardUtil(proxy,dataObj);
        })
        onMounted(async ()=>{})

        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=> {
            res.allPath.forEach((item:any)=>dataObj.otherParams.allPathMenu.push({value:item.path,label:item.caption,item:item}));//构建模块下拉
            res.allTable.forEach((table:any)=>{//构建所有数据库表下拉框（主表下拉框）
                let fields=table.fields.map((field:any)=>{
                    return {prop:field.name,label:field.caption,length:field.length,javaName:field.javaName}
                })
                dataObj.pageData.allTables.push({tableName:table.name,javaName:dataObj.utilInst.formatStr(table.name),tableChineseName:table.caption,fields:fields});
            });
            //赋值属性
            dataObj.pageData.config.tableName=res.data.tableName;
            dataObj.pageData.config.modelName=res.data.modelName;
            dataObj.pageData.config.modelPath=res.data.modelPath;
            dataObj.pageData.config.colNum=res.data.colNum;
            dataObj.pageData.config.fullscreen=res.data.fullscreen=='true'?true:false;//后台是字符串，需要转换为boolean，switch才能正常显示
            if('/load'==addOrLoad){
                let tableInfo=dataObj.pageData.allTables.find((item:any)=>item.tableName==res.data.tableName);//查找选中的主表
                dataObj.utilInst.allFieldsInit([...tableInfo.fields]);//把主表的所有字段赋值给【查询条件、列表表格列、卡片表单】的下拉框
                //下方值得商讨，以上已经为【查询条件、列表表格列、卡片表单】中的allFields赋值了，下方这样赋值会覆盖上面的操作，
                //如果保存时候的allFields和现在主表的allFields不一样，就出问题了。但是下方却是保存时候的状态，所以难取舍。
                if(res.data.listCondition)dataObj.pageData.listCondition=JSON.parse(res.data.listCondition);
                if(res.data.listGrid)dataObj.pageData.listGrid=JSON.parse(res.data.listGrid);
                if(res.data.cardForm)dataObj.pageData.cardForm=JSON.parse(res.data.cardForm);
                if(res.data.cardDetails)dataObj.pageData.cardDetails=JSON.parse(res.data.cardDetails);
                if(dataObj.pageData.cardDetails.tables!=0){//如果有明细表格，则需要指定当前激活的tab
                    dataObj.otherParams.cardDetails.field.tableName=dataObj.pageData.cardDetails.tables[0].tableName;
                }
                await nextTick(async ()=>{
                    await dataObj.utilInst.createContentSortable({sorterOuter:'.sorterOuter',sorterItem:'sorterItem'});//设定页面元素哪些可以拖动
                })
            }
        }

        //双击某个字段进行属性设置(options是selectTag组件传过来的参数)
        const dbHandler=(options:any)=>{
            dataObj.otherParams.fieldItem=options.field;//记录当前操作的字段
            switch (options.type) {
                case 'cardForm'://修改的是表单字段
                    dataObj.otherParams.fieldCfgParam={type:0,cfgName: options.type};
                    break;
                case 'cardDetails'://修改的是明细表格字段
                    dataObj.otherParams.fieldCfgParam={type:1,cfgName: options.type};
                    dataObj.otherParams.cardDetails.field.tableName=options.tableName;//记录当前操作的tab
                    break;
                case 'listCondition'://修改的是列表查询字段
                    dataObj.otherParams.fieldCfgParam={type:0,cfgName: options.type};
                    break;
                case 'listGrid'://修改的是列表表格列
                    dataObj.otherParams.fieldCfgParam={type:1,cfgName: options.type};
                    break;
            }
            dataObj.refMap.get('fieldCfgRef').otherParams.visible=true;//打开弹出框
        }
        //关闭/移除某个字段
        const closeItem=(options:any)=>{
            dataObj.utilInst.closeItem(options);
        }
        //保存
        const saveHandler=async (engineInst:any)=>{
            if(!dataObj.pageData.config.tableName){
                proxy.$message('请选择主表');
                return;
            }
            dataObj.refMap.get('formRef').validate(async (valid:BufferSource) => {
                if (valid) {//如果验证通过
                    let saveData=Object.assign({},dataObj.pageData);
                    delete saveData.allTables;
                    delete saveData.config;
                    saveData=Object.assign(saveData,dataObj.pageData.config,{id:dataObj.form.id});
                    let res = await utils.Api.postRequest({url: dataObj.compParams.modelPath+'/save', params: saveData});
                    if(res.result){
                        utils.Tools.success({message: res.msg});
                        await engineInst.engineUtil.doAddOrLoad(res.data.id,'card');
                        engineInst.engineParams.ownerComp.queryHandler(false);
                    }
                }
            })
        }
        //添加明细
        const addDetailHandler=()=>{
            dataObj.otherParams.cardDetails.visible=true;
        }
        //移除tab
        const removeTab=(activeName:any)=>{
            dataObj.pageData.cardDetails.tables=dataObj.pageData.cardDetails.tables.filter((item:any)=>item.tableName!=activeName);
            if(dataObj.pageData.cardDetails.tables!=0){//移除tab之后，必须给tableName赋值，否则可能什么都不显示
                dataObj.otherParams.cardDetails.field.tableName=dataObj.pageData.cardDetails.tables[0].tableName;
            }
        }
        //主表下拉框切换事件
        const tableChange=async (newVal:any,oldVal:any)=>{
            let tableInfo=dataObj.pageData.allTables.find((item:any)=>item.tableName==newVal);//找到切换的主表
            dataObj.utilInst.allFieldsInit([...tableInfo.fields]);//根据新主表的所有字段，分别为【查询条件、列表表格列、卡片表单】的下拉框赋值
            //注意：以下的做法是为了分别为【查询条件、列表表格列、卡片表单】设置默认选项，以及为这些选项设置默认的属性，但是，但是，但是tableInfo.fields数组里面
            //装的是对象，那么你如果把tableInfo.fields数组赋值给另外一个对象，比如下方的dataObj.pageData[type].fieldArr，那么你更改dataObj.pageData[type].fieldArr
            //也会把tableInfo.fields里面的对象改了，因为数组中的元素是同一个对象的引用。我采用slice、Object.assign、[...]，然而并没有什么卵用，最好的解决办法是：
            //先把tableInfo.fields转为字符串，然后把这个字符串转为对象再赋值给dataObj.pageData[type].fieldArr，这样改变dataObj.pageData[type].fieldArr里面的对象就
            //不会有问题了。
            let jsonStr=JSON.stringify(tableInfo.fields);
            ['cardForm','listCondition','listGrid'].forEach((type:string)=>{
                dataObj.pageData[type].fieldArr=JSON.parse(jsonStr);//默认让所有字段都选择上.这样才能保证它们使用的是各自的对象，是不同的对象。
                dataObj.pageData[type].fieldArr=dataObj.pageData[type].fieldArr.map((item:any)=>{//设置字段类型默认值
                    if('listGrid'==type){//列表表格的字段默认是text且居中
                        item.type='text';
                        if(!item.align)item.align='center';
                        if(!item.headerAlign)item.headerAlign='center';
                        if(!item.width)item.width=0;
                    }else{//列表查询条件、卡片表单的字段默认是input
                        if(!item.type)item.type='input';
                        if('cardForm'==type && item.type=='input' && !item.maxlength){
                            item.maxlength=item.length;
                        }
                        item.disabled=false;
                        item.clearable=true;
                    }
                    return item;
                })
            })
            await nextTick(async ()=>{
                await dataObj.utilInst.createContentSortable({sorterOuter:'.sorterOuter',sorterItem:'sorterItem'});//让表单字段可以拖动
            })
        }
        //SelectTag组件中的下拉select改变事件（由于在SelectTag组件中fieldArr是只读的，所以SelectTag组件修改的是副本，需要传出来改变父类中的fieldArr）
        const changeHandler=(options:any)=>{
            switch (options.type) {
                case 'cardForm'://修改的是表单字段
                case 'listCondition'://修改的是列表查询字段
                case 'listGrid'://修改的是列表表格列
                    dataObj.pageData[options.type].fieldArr=options.newVal;
                    break;
                case 'cardDetails'://修改的是明细表格字段
                    dataObj.pageData.cardDetails.tables=dataObj.pageData.cardDetails.tables.map((item:any)=>{
                        if(item.tableName==options.tableName){//找到明细表
                            item.fieldArr=options.newVal;
                        }
                        return item;
                    });
                    break;
            }
        }
        return{
            ...toRefs(dataObj),beforeOpen,closeItem,saveHandler,dbHandler,addDetailHandler,removeTab,tableChange,changeHandler
        }
    }
});