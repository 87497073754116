import BaseBean from "@/utils/BaseBean";
import Sortable from "sortablejs";
import {nextTick} from 'vue';
export interface IBuildPageCardDataObj {
    utilInst:BuildPageCardUtil
    refMap:Map<string,any>
    formRef:any
    form: any
    rules: any
    compParams: any
    pageData:any
    otherParams:any
}

export default class BuildPageCardUtil extends BaseBean{
    public dataObj:IBuildPageCardDataObj

    constructor(proxy:any,dataObj:IBuildPageCardDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //创建页面拖动元素
    public async createContentSortable(options:any):Promise<void>{
        let dom:any=document.querySelectorAll(options.sorterOuter);//明细表格可能有多个，所以用querySelectorAll
        if(!dom)return;//表单和明细表格公用的，所以判断一下是否存在dom元素
        let utilInst:BuildPageCardUtil=this;
        dom.forEach((domItem:any)=>{
            let instance=new Sortable(domItem, {
                group: options.sorterItem,
                animation: 150,
                dataIdAttr: "data-id",
                forceFallback: true,
                scroll: false,
                onStart: function (evt) {},
                onMove:function(evt:any){
                    utilInst.dataObj.otherParams.moveItem={dragged:evt.dragged,related:evt.related};
                    return false;//只记录下正在移动的项，不改变顺序，真正改变顺序放在onEnd里面，要不然这里改变的话，一移动就变，很烦，所以返回false
                },
                onEnd:function(evt:any){
                    if(utilInst.dataObj.otherParams.moveItem.dragged){//稍微移动一下某项，不和其它项重合的时候，dragged为undefined
                        let type=utilInst.dataObj.otherParams.moveItem.dragged.dataset.type;//正在移动项的类别，查询条件|列表表格列|卡片表单|表单明细表格
                        let moveField=utilInst.dataObj.otherParams.moveItem.dragged.dataset.id;//移动项
                        let toIdField=utilInst.dataObj.otherParams.moveItem.related.dataset.id;//被替换项
                        switch (type) {
                            case 'cardDetails'://拖动的是卡片明细表格字段列
                                let tablename=utilInst.dataObj.otherParams.moveItem.dragged.dataset.tablename;//移动字段所属tab
                                utilInst.moveDetailField(moveField,toIdField,tablename);
                                break;
                            case 'cardForm':   //拖动的是表单字段
                            case 'listCondition': //拖动的是列表查询字段
                            case 'listGrid'://拖动的是列表表格列
                                utilInst.moveSpeField(moveField,toIdField,utilInst.dataObj.pageData[type].fieldArr,type);
                                break;
                        }
                    }
                }
            })
        })
    }
    //查找某个字段在指定字段集合fields中的位置
    public findFieldIndex(prop:any,fields:Array<any>):number{
        return fields.findIndex((item:any)=>item.prop==prop)
    }
    //移动列表查询条件|列表表格列|卡片表单中的字段
    public moveSpeField(moveField:any,toIdField:any,fieldArr:any,type:string):void{
        let oldIndex=this.findFieldIndex(moveField,fieldArr);
        let newIndex=this.findFieldIndex(toIdField,fieldArr);
        [this.dataObj.pageData[type].fieldArr[newIndex],this.dataObj.pageData[type].fieldArr[oldIndex]]=
            [this.dataObj.pageData[type].fieldArr[oldIndex],this.dataObj.pageData[type].fieldArr[newIndex]];
    }
    //移动明细表格的字段
    public moveDetailField(moveField:any,toIdField:any,tableName:any):void{
        this.dataObj.pageData.cardDetails.tables.some((item:any)=>{
            if(item.tableName==tableName){//找到移动字段所属表
                //明细表格要显示哪些字段是根据fieldArr来的，所以这里更改的是fieldArr里面的顺序
                let oldIndex=this.findFieldIndex(moveField,item.fieldArr);
                let newIndex=this.findFieldIndex(toIdField,item.fieldArr);
                [item.fieldArr[newIndex],item.fieldArr[oldIndex]]=[item.fieldArr[oldIndex],item.fieldArr[newIndex]];
                return true;
            }
        })
    }
    //关闭（去除）元素
    public closeItem(options:any):void{
        switch (options.type) {
            case 'cardDetails'://卡片表单明细
                this.dataObj.pageData.cardDetails.tables=this.dataObj.pageData.cardDetails.tables.filter((item:any)=>{
                    if(item.tableName==options.tableName){//找到移动的明细
                        let newFields=item.fieldArr.filter((_item:any)=>_item.prop!=options.prop);
                        item.fieldArr=newFields;
                    }
                    return item;
                })
                break;
            case 'cardForm'://卡片表单
            case 'listCondition'://列表查询字段
            case 'listGrid'://列表表格列表字段
                this.dataObj.pageData[options.type].fieldArr=this.dataObj.pageData[options.type].fieldArr.filter((item:any)=>item.prop!=options.prop);
                break;
        }
    }
    //字段属性设置确定处理(列表查询条件|列表表格列|卡片表单字段)
    public doFieldInfoSure(type:string):void{
        //改变已选字段
        this.dataObj.pageData[type].fieldArr=this.dataObj.pageData[type].fieldArr.map((item:any)=>{
            if(item.prop==this.dataObj.otherParams.fieldItem.prop){//找到被修改的字段重新赋值
                item=this.dataObj.otherParams.fieldItem;
            }
            return item;
        });
        //改变select的数据源（所有字段），否则会造成已选的在数据源中找不到的错误，所以还得改变数据源
        this.dataObj.pageData[type].allFields=this.dataObj.pageData[type].allFields.map((item:any)=>{
            if(item.prop==this.dataObj.otherParams.fieldItem.prop){//找到被修改的字段重新赋值
                item=this.dataObj.otherParams.fieldItem;
            }
            return item;
        });
    }
    //字段属性设置确定事件
    public fieldInfoSure(type:string):void{
        switch (type) {
            case 'cardDetails':
                this.dataObj.pageData.cardDetails.tables=this.dataObj.pageData.cardDetails.tables.map((item:any)=>{
                    if(item.tableName==this.dataObj.otherParams.cardDetails.field.tableName){//找到明细表
                        let tempFieldArr=item.fieldArr.map((_item:any)=>{
                            if(_item.prop==this.dataObj.otherParams.fieldItem.prop){//找到被修改的字段重新赋值
                                _item=this.dataObj.otherParams.fieldItem;
                            }
                            return _item;
                        });
                        item.fieldArr=tempFieldArr;

                        let tempFields=item.fields.map((_item:any)=>{
                            if(_item.prop==this.dataObj.otherParams.fieldItem.prop){//找到被修改的字段重新赋值
                                _item=this.dataObj.otherParams.fieldItem;
                            }
                            return _item;
                        });
                        item.fields=tempFields;
                    }
                    return item;
                });
                break;
            case 'cardForm'://修改的是卡片表单字段
            case 'listCondition'://修改的是列表查询字段
            case 'listGrid'://修改的是列表表格列字段
                this.doFieldInfoSure(type);
                break;
        }
    }

    //增加卡片明细确认事件
    public async addTabSure():Promise<void>{
        let existTable=this.dataObj.pageData.cardDetails.tables.find((item:any)=>item.tableName==this.dataObj.otherParams.cardDetails.addTableName);
        if(existTable){
            this.proxy.$message('该明细已存在!');
            return ;
        }
        let table=this.dataObj.pageData.allTables.find((item:any)=>item.tableName==this.dataObj.otherParams.cardDetails.addTableName);//明细表格对应的表
        let addTableInfo=JSON.parse(JSON.stringify(table));//为什么这样搞，和主表切换事件tableChange的解释一样的
        addTableInfo.fields=addTableInfo.fields.map((item:any)=>{ //设置明细表格列的默认值
            if(!item.type)item.type='text';
            if(!item.align)item.align='center';
            if(!item.headerAlign)item.headerAlign='center';
            if(!item.width)item.width=0;
            return item;
        })
        addTableInfo.fieldArr=addTableInfo.fields;//默认全选所有的字段
        this.dataObj.pageData.cardDetails.tables.push(addTableInfo);//添加tab
        this.dataObj.otherParams.cardDetails.visible=false;//关闭弹出框
        this.dataObj.otherParams.cardDetails.field.tableName=this.dataObj.otherParams.cardDetails.addTableName;//设置默认选择的tab为新添加的tab
        await nextTick(async ()=>{
            await this.createContentSortable({sorterOuter:'.sorterOuter',sorterItem:'sorterItem'});//设置页面哪些元素可以拖动
        })
    }
    //主表所有字段赋值给需要的下拉select
    public allFieldsInit(fields:any):void{
        ['listCondition','listGrid','cardForm'].forEach((type:string)=>{
            this.dataObj.pageData[type].allFields=fields;
        })
    }
    //---------------------------utils
    //将表名转为驼峰
    public formatStr(str:string):string {
        if(str.indexOf('_')==-1)return str.charAt(0).toLowerCase() + str.slice(1);
        const parts = str.split('_');
        parts.shift(); // 去掉第一个部分
        return parts.map((part:string, index:number) => {
            if (index === 0) {
                return part.toLowerCase(); // 第一个部分小写
            }
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }).join('');
    }
}